<template>
  <div class="phone-model">
    <div class="list-container">
      <div class="title">产品：</div>
      <div class="list stuff-list">
        <div
          class="item"
          :class="{ active: stuffIndex === index }"
          v-for="(item, index) of list"
          :key="index"
          @click="handleStuffItemClick(index)"
        >
          {{ item.stuff }}
        </div>
      </div>
    </div>
    <div class="list-container">
      <div class="title">品牌：</div>
      <div class="list stuff-list">
        <div
          class="item"
          :class="{ active: brandIndex === index }"
          v-for="(item, index) of list[stuffIndex].brands"
          :key="index"
          @click="handleBrandItemClick(index)"
        >
          {{ item.brand_name }}
        </div>
      </div>
    </div>
    <div class="list-container">
      <div class="title">型号：</div>
      <div class="list stuff-list">
        <div
          class="item"
          v-for="(item, index) of list[stuffIndex].brands[brandIndex].types"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhoneModel",
  created() {
    this.getModelList();
  },
  data() {
    return {
      stuffIndex: 0,
      brandIndex: 0,
      list: [],
    };
  },
  methods: {
    async getModelList() {
      const res = await this.$store.dispatch("getModelList");
      const stuffs = res.stuffs;
      const list = Object.keys(stuffs).map((item) => {
        const brands = Object.values(stuffs[item]);
        return {
          stuff: item,
          brands,
        };
      });
      this.list = list;
      console.log(list);
    },
    handleStuffItemClick(index) {
      this.stuffIndex = index;
      this.brandIndex = 0;
    },
    handleBrandItemClick(index) {
      this.brandIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.list-container {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    margin-bottom: 10px;
    font-size: 16px;
    padding: 5px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #909399;
  }
  .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-left: 5px;
      margin-bottom: 5px;
      padding: 5px 10px;
      color: #fff;
      border-radius: 5px;
      background-color: #909399;
      display: flex;
      font-size: 14px;
      box-sizing: border-box;
      cursor: pointer;
      &.active {
        background-color: #f56c6c;
      }
    }
  }
}
</style>